import configs from '@/configs'
import { DEFAULT_VRAM_KB } from '@/constants/device'
import { DeviceType } from '@/types/configs'

export const getTotalVramKb = (deviceType: DeviceType) => {
  const deviceToVram = configs.appConfig?.features?.device_to_vram! || []
  const totalVramKb =
    deviceToVram?.find((device) => device.device === deviceType)?.top_vram_kb || DEFAULT_VRAM_KB

  return totalVramKb
}
