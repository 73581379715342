'use client'

import { getDefaultDeviceVramTitle, getDevice } from '@/lib/is-mobile'
import { PropsWithChildren, createContext, useContext } from 'react'
import { getTotalVramKb } from '@/lib/device'
import { DeviceType } from '@/types/configs'

export type DefaultDeviceVramTitle = Awaited<ReturnType<typeof getDefaultDeviceVramTitle>>

type Device = Awaited<ReturnType<typeof getDevice>>

type UAContextType = {
  isAndroidMobile: boolean
  isMobileApp: boolean
  defaultDeviceVramTitle: DefaultDeviceVramTitle
  device: Device
  deviceType: DeviceType
  totalVramKb?: number
}

const UAContext = createContext<UAContextType | null>(null)

export function UAProvider({
  isAndroidMobile,
  isMobileApp,
  defaultDeviceVramTitle,
  deviceType,
  device,
  children,
}: PropsWithChildren<UAContextType>) {
  const totalVramKb = getTotalVramKb(deviceType)
  return (
    <UAContext.Provider
      value={{
        isAndroidMobile,
        isMobileApp,
        defaultDeviceVramTitle,
        device,
        deviceType,
        totalVramKb,
      }}
    >
      {children}
    </UAContext.Provider>
  )
}

export const useUAContext = () => {
  const value = useContext(UAContext)

  if (value == null) {
    throw new Error('useUAContext cannot be used outside of UAProvider')
  }

  return value
}
