import { API_GET_LOGIN_STATUS, API_GET_ME_V4 } from '@/services/api'
import { fetchCMSApiWithStatusCode } from '@/services/cms'
import { SEARCH_PARAM_KEYS } from '@/constants/auth'
import { getHubsDomain } from '@/configs/utils'

const { HTC_TOKEN, RETICULUM_TOKEN, EMAIL, RETRY_AFTER, IS_NEW_USER } = SEARCH_PARAM_KEYS

type MyProfileResponse = {
  id: string
  htc_account_id: string
  htc_account_email: string
  htc_account_phone_number: string
  wallet_address: string
  display_name: string
  is_admin: boolean
  liked_rooms: string[]
  liked_events: string[]
  oursong_account_id: string
  is_over_18: boolean
  active_avatar: {
    id: string
    snapshot_url: string
    avatar_url: string
    head_icon_url: string
    gender: number
    data_type: number
    is_half_body: boolean
  }
  community_user_id: string
}

export type GetProfileMeResponse = {
  id: string
  displayName: string
  walletAddress: string
  accountEmail: string
  accountPhoneNumber: string
  isOver18: boolean
  userId: string
  activeAvatar: {
    id: string
    snapshot: string
    glbUrl: string
    dataType: number | null
    gender: number
    isHalfBody: boolean
  }
}

export const getProfileMe = async (htcToken: string): Promise<GetProfileMeResponse | null> => {
  const myProfileResult = (
    await fetchCMSApiWithStatusCode<MyProfileResponse>(API_GET_ME_V4, 'GET', null, {}, htcToken)
  )?.results

  if (!myProfileResult) {
    return null
  }

  const {
    id,
    display_name: displayName = '',
    active_avatar: activeAvatar = null,
    wallet_address: walletAddress = '',
    htc_account_email: accountEmail = '',
    htc_account_phone_number: accountPhoneNumber = '',
    is_over_18: isOver18 = false,
    community_user_id: userId = '',
  } = myProfileResult

  return {
    id,
    displayName,
    walletAddress,
    accountEmail,
    accountPhoneNumber,
    isOver18,
    userId,
    activeAvatar: {
      id: activeAvatar?.id || '',
      snapshot: activeAvatar?.head_icon_url || activeAvatar?.snapshot_url || '',
      glbUrl: activeAvatar?.avatar_url || '', // 3D model of the avatar
      dataType: activeAvatar?.data_type || null, // Data type from vive avatar
      gender: activeAvatar?.gender || 0, // 0 for female and 1 for male
      isHalfBody: activeAvatar?.is_half_body || false,
    },
  }
}

type LoginStatusResponse = {
  [RETRY_AFTER]: number
  [RETICULUM_TOKEN]: string
  [IS_NEW_USER]: boolean
  [HTC_TOKEN]: string
  [EMAIL]: string
}

export const getLoginStatus = (): Promise<LoginStatusResponse | void> => {
  const hubsDomain = getHubsDomain()
  return fetch(`https://${hubsDomain}${API_GET_LOGIN_STATUS}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json()
      }
    })
    .catch((error) => {
      console.error('getLoginStatus error:', error)
    })
}
