'use client'

import { useCallback } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'

import { BI_CATEGORY_SIGN_UP, BI_EVENT_WORLD_SIGN_UP } from '@/constants/bi-sdk'
import { ProfileIdSchema, ProfileUserIdSchema, ProfileAvatarSchema } from '@/schemas/auth'
import { getProfileMe } from '@/services/auth'
import { getViveCreatorUrl } from '@/lib/url'
import { useConfigsContext } from '@/context/ConfigsContext'
import { useBISdkContext } from '@/context/BISdkContext'
import { useUAContext } from '@/context/UAContext'
import { HubsStore } from '@/types/store'
import useGetCommunityToggle from '@/hooks/auth/useGetCommunityToggle'
import { createProfile, createViveportAuth, extractUsernameFromEmail } from '@/lib/auth'
import { enableLoginStatusCheck } from '@/configs/utils'

const useHandleProfileResult = (setIsSignedIn: (isSignedIn: boolean) => void) => {
  const getCommunityToggle = useGetCommunityToggle()
  const { sendBIReport } = useBISdkContext()
  const { setStore } = useConfigsContext()
  const { isMobileApp } = useUAContext()

  return useCallback(
    async (credentials: HubsStore['credentials'], qsHtcToken: string | null) => {
      const htcToken = credentials?.htcToken
      const email = credentials?.email
      const result = await getProfileMe(htcToken!)

      if (!result) {
        throw new Error('Error while fetching profile')
      }

      const { id, displayName, activeAvatar, userId } = result
      const htcAccountId = extractUsernameFromEmail(email)

      const isIdNotSet = !ProfileIdSchema.safeParse({ id }).success
      const isUserIdNotSet = !ProfileUserIdSchema.safeParse({ userId }).success
      const isNewUser = !ProfileAvatarSchema.safeParse({ displayName, activeAvatar }).success

      const communityToggle = await getCommunityToggle(credentials)
      const isProfileNotSet = isNewUser || (communityToggle && isUserIdNotSet)

      if (isNewUser) {
        sendBIReport({
          category: BI_CATEGORY_SIGN_UP,
          ev: BI_EVENT_WORLD_SIGN_UP,
          account_id: htcAccountId,
        })
      }

      if (isIdNotSet) {
        // TODO: handle error
        throw new Error(`Couldn't get user id`, { cause: 'connect-failed' })
      } else if (isProfileNotSet) {
        const viveCreatorUrl = getViveCreatorUrl()
        // force redirect to vive creator page
        window.location.href = viveCreatorUrl
      } else {
        const profile = createProfile(result)
        const viveportAuth = createViveportAuth(result)

        setStore((value) => ({
          ...value,
          viveportAuth: {
            ...value.viveportAuth,
            ...viveportAuth,
          },
          profile: {
            ...value.profile,
            ...profile,
          },
          communityToggle,
          credentials,
        }))

        setIsSignedIn(true)

        if (isMobileApp && 'flutterWebview' in window) {
          // TODO: define flutterWebview type
          // @ts-ignore
          window.flutterWebview.postMessage(
            JSON.stringify({
              feature: 'profile',
              data: {
                headIconUrl: profile.avatarSnapshot,
                displayName: profile.displayName,
              },
            }),
          )
        }
      }

      if (enableLoginStatusCheck() && qsHtcToken) {
        if (!isNewUser) {
          console.log('[GTM] send login gtm')
          sendGTMEvent({ event: 'login', account_id: htcAccountId })
        } else {
          console.log('[GTM] send signup gtm')
          sendGTMEvent({ event: 'signup', account_id: htcAccountId })
        }
      }
    },
    [getCommunityToggle, sendBIReport, setIsSignedIn, setStore, isMobileApp],
  )
}

export default useHandleProfileResult
