'use client'

import { CredentialsTokensSchema } from '@/schemas/auth'
import { useConfigsContext } from '@/context/ConfigsContext'
import { useState } from 'react'

const useIsSignedIn = () => {
  const { store } = useConfigsContext()
  const token = store?.credentials?.token
  const htcToken = store?.credentials?.htcToken
  const isSignedIn = CredentialsTokensSchema.safeParse({ token, htcToken }).success
  return useState(isSignedIn)
}

export default useIsSignedIn
