import { getCommunityServiceBaseURL } from '@/configs/utils'
import { API_CHECK_USER_COMMUNITY_PERMISSION } from '@/services/api'
import { getPublicRooms, Room } from '@/services/cms'
import { getCurrentAvatarList } from '@/services/avatar'
import { MASTODON_SOCIAL_DOMAIN } from '@/constants/community'

export const fetchCommunityAPI = <Response = Record<string, unknown>>(
  url: string,
  method: RequestInit['method'] = 'GET',
  payload: RequestInit['body'],
  params: Omit<RequestInit, 'method' | 'body'> = {},
  htcToken = '',
): Promise<{ status: number; results: Response; headers: Headers } | void> => {
  const init: RequestInit = {
    headers: { 'content-type': 'application/json' },
    method,
    ...params,
  }
  if (htcToken) {
    init.headers = {
      ...init.headers,
      ...{ authkey: htcToken },
    }
  }
  if (payload) {
    init.body = JSON.stringify(payload)
  }
  const communityServiceBaseURL = getCommunityServiceBaseURL()
  return fetch(`${communityServiceBaseURL}${url}`, init)
    .then(async (response) => {
      const result = await response.text()
      try {
        return { status: response.status, results: JSON.parse(result), headers: response.headers }
      } catch (e) {
        // Don't return json.
        return { status: response.status, results: result, headers: response.headers }
      }
    })
    .catch((error) => {
      console.error('fetchCMSApiWithStatusCode error:', error)
    })
}

export const fetchCheckUserCommunityPermission = async (htcToken: string) => {
  const url = API_CHECK_USER_COMMUNITY_PERMISSION
  const res = await fetchCommunityAPI(url, 'Get', null, {}, htcToken)
  return res
}

export type Following = {
  domainName: string
  name: string
  nickName: string
  preferredUsername: string
  userIri: string
}

export type PaginationData = {
  from: number
  size: number
  total: number
}

export type FollowingsResults = Partial<PaginationData> & {
  data: (Following | FollowingWithIcon | FollowingWithRooms)[]
}

export type FollowingWithAccountId = Following & {
  htcAccountId: string
}

export type FollowingWithIcon = FollowingWithAccountId & {
  headIconUrl: string
}

export type FollowingWithRooms = FollowingWithAccountId & {
  publicRooms: Room[]
}

export async function getFollowings(htcToken: string, from: number = 0, size: number = 0) {
  return fetchCommunityAPI<FollowingsResults>(
    `/api/community-service/v1/followings?from=${from}&size=${size}`,
    'GET',
    null,
    {},
    htcToken,
  )
}

export async function getFollowingsWithAccountId(htcToken: string): Promise<{
  status: number
  results: FollowingsResults
  headers: Headers
} | void> {
  const communityServiceBaseURL = getCommunityServiceBaseURL()
  const followingsResponse = await getFollowings(htcToken)
  let followingsWithAccountId: FollowingWithAccountId[] = []

  if (followingsResponse?.results && Array.isArray(followingsResponse.results.data)) {
    const followingsData = followingsResponse.results.data
    followingsWithAccountId = followingsData.map((following) => ({
      ...following,
      htcAccountId: following.userIri
        ? following.userIri.replace(`${communityServiceBaseURL}/users/`, '')
        : '',
    }))
  } else {
    return followingsResponse
  }

  return {
    results: { data: followingsWithAccountId },
    status: 200,
    headers: followingsResponse?.headers || new Headers(),
  }
}

function getHtcAccountIdsFromFollowings(followings: Following[]) {
  const communityServiceBaseURL = getCommunityServiceBaseURL()

  return followings.map((following) => {
    const htcAccountId = following.userIri.replace(`${communityServiceBaseURL}/users/`, '')
    return htcAccountId
  })
}

export async function getFollowingsWithIcon(htcToken: string): Promise<{
  status: number
  results: FollowingsResults
  headers: Headers
} | void> {
  const response = await getFollowings(htcToken)

  if (!response?.results || !Array.isArray(response.results.data)) return response

  const data = response.results.data
  const allowedData = data.filter(({ domainName }) => !domainName.includes(MASTODON_SOCIAL_DOMAIN))
  response.results.data = allowedData

  if (allowedData.length < 1) {
    return response
  }

  const htcAccountIds = await getHtcAccountIdsFromFollowings(allowedData)
  const avatarResponse = await getCurrentAvatarList(htcToken, htcAccountIds)
  const finalData: FollowingWithIcon[] = []

  if (avatarResponse?.status === 200 && avatarResponse.results.data) {
    const avatarsData = avatarResponse.results.data.Avatars

    // @ts-ignore
    allowedData.forEach((follower, i) => {
      const avatar = avatarsData.find(({ ViveportId }: any) => ViveportId === htcAccountIds[i])
      if (avatar) {
        finalData.push({
          ...follower,
          htcAccountId: htcAccountIds[i],
          headIconUrl: avatar.HeadIconDataUrl,
        })
      }
    })
  }

  return {
    results: { data: finalData },
    status: 200,
    headers: response?.headers || new Headers(),
  }
}

export async function getFollowingsWithRooms(
  htcToken: string,
  totalVramKb: number,
  from: number = 0,
  size: number = 0,
) {
  const followingsResponse = await getFollowings(htcToken, from, size)
  const communityServiceBaseURL = getCommunityServiceBaseURL()

  if (followingsResponse?.results && Array.isArray(followingsResponse.results.data)) {
    const followings = followingsResponse.results.data
    const visibleFollowings = followings.filter(
      ({ domainName }) => !domainName.includes(MASTODON_SOCIAL_DOMAIN),
    )

    if (visibleFollowings.length > 0) {
      const followingsWithDetails = await Promise.all(
        visibleFollowings.map(async (following) => {
          const htcAccountId = following.userIri.replace(`${communityServiceBaseURL}/users/`, '')
          const response = await getPublicRooms(
            { htc_account_id: htcAccountId, total_vram_kb: totalVramKb },
            htcToken,
          )
          if (response?.status !== 200) return following

          return {
            ...following,
            publicRooms: response.results.results,
            htcAccountId,
          }
        }),
      )
      followingsResponse.results.data = followingsWithDetails
    } else {
      followingsResponse.results.data = visibleFollowings
    }
  }

  return followingsResponse
}

export async function getFollowers(htcToken: string) {
  return fetchCommunityAPI<FollowingsResults>(
    '/api/community-service/v1/followers',
    'GET',
    null,
    {},
    htcToken,
  )
}
export async function getFollowersWithIcon(htcToken: string): Promise<{
  status: number
  results: FollowingsResults
  headers: Headers
} | void> {
  const response = await getFollowers(htcToken)

  if (!response?.results || !Array.isArray(response.results.data)) return response

  const data = response.results.data
  const allowedData = data.filter(({ domainName }) => !domainName.includes(MASTODON_SOCIAL_DOMAIN))
  response.results.data = allowedData

  if (allowedData.length < 1) {
    return response
  }

  const htcAccountIds = await getHtcAccountIdsFromFollowings(allowedData)
  const avatarResponse = await getCurrentAvatarList(htcToken, htcAccountIds)
  const finalData: FollowingWithIcon[] = []

  if (avatarResponse?.status === 200 && avatarResponse.results.data) {
    const avatarsData = avatarResponse.results.data.Avatars

    // @ts-ignore
    allowedData.forEach((follower, i) => {
      const avatar = avatarsData.find(({ ViveportId }: any) => ViveportId === htcAccountIds[i])
      if (avatar) {
        finalData.push({
          ...follower,
          htcAccountId: htcAccountIds[i],
          headIconUrl: avatar.HeadIconDataUrl,
        })
      }
    })
  }

  return {
    results: { data: finalData },
    status: 200,
    headers: response?.headers || new Headers(),
  }
}

export async function postUnFollow(
  htcToken: string,
  targetUserIri?: string,
  htcAccountId?: string,
) {
  const communityServiceBaseURL = getCommunityServiceBaseURL()
  if (!targetUserIri && htcAccountId) {
    targetUserIri = `${communityServiceBaseURL}/users/${htcAccountId}`
  }
  return fetchCommunityAPI(
    '/api/community-service/v1/unfollow',
    'POST',
    {
      // @ts-ignore
      targetUserIri,
    },
    {},
    htcToken,
  )
}

export async function postFollow(htcToken: string, targetUserIri?: string, htcAccountId?: string) {
  const communityServiceBaseURL = getCommunityServiceBaseURL()

  if (!targetUserIri && htcAccountId) {
    targetUserIri = `${communityServiceBaseURL}/users/${htcAccountId}`
  }

  return fetchCommunityAPI(
    '/api/community-service/v1/follow',
    'POST',
    {
      // @ts-ignore
      targetUserIri,
    },
    {},
    htcToken,
  )
}

export type UserFollowResult = {
  '@context': string
  first: string
  id: string
  items: string[]
  last: string
  totalItems: number
  type: string
}

export async function getPublicFollowers(htcAccountId: string) {
  return fetchCommunityAPI<UserFollowResult>(`/users/${htcAccountId}/followers`, 'GET', null, {})
}

export async function getPublicFollowings(htcAccountId: string) {
  return fetchCommunityAPI<UserFollowResult>(`/users/${htcAccountId}/following`, 'GET', null, {})
}

type GetPointBalanceResult = {
  data: {
    pointBalance: number
    userId: string
  }
}

/**
 * Get user points balance
 * @see {@link https://htcsense.jira.com/wiki/spaces/NEOSTORE/pages/3479175176/Point+Service+API+Doc#%5BGET%5D-%2Fpriv%2Fpoint-service%2Fv1%2Fpoints%2Fhistory}
 */
export async function getPointBalance(htcToken: string) {
  return fetchCommunityAPI<GetPointBalanceResult>(
    '/api/point-service/v1/points',
    'GET',
    null,
    {},
    htcToken,
  )
}
