'use client'

import { useCallback } from 'react'

import { fetchCheckUserCommunityPermission } from '@/services/community'
import { getEnableCommunity, getEnableCommunityMobileApp } from '@/configs/utils'
import { HubsStore } from '@/types/store'
import { useUAContext } from '@/context/UAContext'

const useGetCommunityToggle = () => {
  const { isMobileApp } = useUAContext()

  return useCallback(
    async (credentials: HubsStore['credentials']) => {
      const htcToken = credentials?.htcToken
      const enableCommunity = getEnableCommunity()
      const enableCommunityMobileApp = getEnableCommunityMobileApp()

      if (!enableCommunity) {
        return false
      }

      if (isMobileApp && !enableCommunityMobileApp) {
        return false
      }

      const hasPermission = await fetchCheckUserCommunityPermission(htcToken!)
      const communityToggle = hasPermission ? true : false

      return communityToggle
    },
    [isMobileApp],
  )
}

export default useGetCommunityToggle
