'use client'

import { useEffect, useState } from 'react'
import { useParams } from 'next/navigation'
import { useIsClient } from 'usehooks-ts'

const getHash = () => (typeof window !== 'undefined' ? window.location.hash : undefined)

const useHash = () => {
  const isClient = useIsClient()
  const [hash, setHash] = useState(getHash())
  const params = useParams()

  useEffect(() => {
    setHash(getHash())
  }, [params])

  return isClient ? hash : null
}

export default useHash
