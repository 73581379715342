import { getViveAvatarDomain } from '@/configs/utils'
import { API_GET_CURRENT_AVATAR_LIST } from '@/services/api'

export const fetchAvatarApiWithStatusCode = <Response = Record<string, unknown>>(
  url: string,
  method: RequestInit['method'] = 'GET',
  payload: RequestInit['body'],
  params: Omit<RequestInit, 'method' | 'body'> = {},
  htcToken = '',
): Promise<{ status: number; results: Response; headers: Headers } | void> => {
  const avatarBaseURL = getViveAvatarDomain()
  const avatarUrl = `https://${avatarBaseURL}${url}`
  const init: RequestInit = {
    headers: {
      'content-type': 'application/json',
      // 'User-Agent': 'HTCVRSDET',
    },
    method,
    ...params,
  }
  if (htcToken) {
    init.headers = {
      ...init.headers,
      ...{ authkey: htcToken },
    }
  }
  if (payload) {
    init.body = JSON.stringify(payload)
  }
  return fetch(avatarUrl, init)
    .then(async (response) => {
      const result = await response.text()
      console.log({ result })
      try {
        return { status: response.status, results: JSON.parse(result), headers: response.headers }
      } catch (e) {
        // Don't return json.
        return { status: response.status, results: result, headers: response.headers }
      }
    })
    .catch((error) => {
      console.error('fetchAvatarApiWithStatusCode error:', error)
    })
}

export function getCurrentAvatarList(htcToken: string, htcAccountIds: string[]) {
  return fetchAvatarApiWithStatusCode<any>(
    API_GET_CURRENT_AVATAR_LIST,
    'POST',
    // @ts-ignore
    { accountIds: htcAccountIds },
    {},
    htcToken,
  )
}
