import { z } from 'zod'

export const CredentialsSchema = z.object({
  token: z.string().min(1),
  email: z.string().email(),
  htcToken: z.string().min(1),
  expiredAt: z.number(),
})

export const CredentialsTokensSchema = CredentialsSchema.pick({ token: true, htcToken: true })

const ProfileSchema = z.object({
  id: z.string().min(1),
  displayName: z.string().min(1),
  walletAddress: z.string().min(1),
  accountEmail: z.string().email(),
  accountPhoneNumber: z.string().min(1),
  isOver18: z.boolean(),
  userId: z.string().min(1),
  activeAvatar: z.object({
    id: z.string().min(1),
    snapshot: z.string().min(1),
    glbUrl: z.string().min(1),
    dataType: z.number(),
    gender: z.number(),
    isHalfBody: z.boolean(),
  }),
})

export const ProfileIdSchema = ProfileSchema.pick({ id: true })
export const ProfileUserIdSchema = ProfileSchema.pick({ userId: true })
export const ProfileAvatarSchema = ProfileSchema.pick({ activeAvatar: true, displayName: true })
