import { useCallback } from 'react'
import { useConfigsContext } from '@/context/ConfigsContext'
import { getLoginStatus } from '@/services/auth'
import { SEARCH_PARAM_KEYS } from '@/constants/auth'
import { enableLoginStatusCheck } from '@/configs/utils'

const { RETRY_AFTER, HTC_TOKEN, EMAIL, RETICULUM_TOKEN } = SEARCH_PARAM_KEYS

const useCheckLoginStatus = () => {
  const { store, removeStore } = useConfigsContext()
  const token = store?.credentials?.token

  return useCallback(
    async (qs: URLSearchParams) => {
      if (!enableLoginStatusCheck()) {
        return
      }

      const res = await getLoginStatus()
      if (!res) {
        token && removeStore()
      } else {
        qs.set(RETRY_AFTER, res[RETRY_AFTER].toString())
        qs.set(HTC_TOKEN, res[HTC_TOKEN])
        qs.set(EMAIL, res[EMAIL])
        qs.set(RETICULUM_TOKEN, res[RETICULUM_TOKEN])
      }
    },
    [token, removeStore],
  )
}

export default useCheckLoginStatus
